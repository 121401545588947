import React, { useState } from 'react';
import { TopBarCompanyListStyle as S } from './TopBarCompanyList.style';
import { useObservableState } from 'observable-hooks';
import { getCustomerStore } from 'stores/customer.store';
import { PAutoComplete } from 'components/prime/PAutoComplete/PAutoComplete';
import { Customer } from 'types/Customer';

export const TopBarCompanyList: React.FC = () => {
  const customers = useObservableState(getCustomerStore().$customers, []);
  const customer = useObservableState(getCustomerStore().$customer);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  return (
    <S.Container>
      <PAutoComplete
        value={selectedCustomer}
        suggestions={filteredCustomers}
        completeMethod={(e) => {
          e.query.trim().length === 0
            ? setFilteredCustomers([...customers])
            : setFilteredCustomers(
                customers.filter((customer) => customer.name.toLowerCase().startsWith(e.query.toLowerCase()))
              );
        }}
        scrollHeight="calc(70vh - 56px)"
        field="name"
        dropdown
        delay={0}
        onChange={(e) => setSelectedCustomer(e.value)}
        disabled={customers.length <= 1}
        placeholder={customer?.name}
        forceSelection
        autoHighlight
        onSelect={(e) => {
          if (e.value.slug !== customer?.slug) {
            window.location.href = `${window.location.origin}/${e.value.slug}/get-started`;
          }
        }}
        itemTemplate={(item) => <span title={item?.name}>{item?.name}</span>}
        panelClassName="yabble-top-bar-company-list-dropdown-panel"
        data-testid="input-top-bar-company"
      />
      <S.CompanyDropdownGlobal />
    </S.Container>
  );
};
