import { createGlobalStyle } from 'styled-components';

export const ManageProjectPermissionsUserSearchStyle = {
  DropdownsGlobal: createGlobalStyle`
    .project-permission-users-search-panel {
      max-width: 416px !important;
      li {white-space: initial;}
    }
  `,
};
