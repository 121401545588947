import styled, { css } from 'styled-components';
import { borders, colourPalette, padding, borderRadius, shadow, hexToRGBA } from 'styles/styles';

const getPadding = (value?: keyof typeof padding | (keyof typeof padding)[]) => {
  if (typeof value === 'string')
    return css`
      padding: ${padding[value]};
    `;
  if (Array.isArray(value))
    return css`
      padding: ${value.map((p) => padding[p]).join(' ')};
    `;
  return '';
};

const getBorderWidth = (value?: keyof typeof borders | (keyof typeof borders)[]) => {
  if (typeof value === 'string')
    return css`
      border-width: ${borders[value]};
    `;
  if (Array.isArray(value))
    return css`
      border-width: ${value.map((p) => borders[p]).join(' ')};
    `;
  return '';
};

export const BoxStyle = {
  Box: styled.div<{ $props: any }>`
    ${({ $props }) => css`
      ${$props.radius
        ? css`
            border-radius: ${(borderRadius as any)[$props.radius]};
          `
        : ''}
      ${$props.backgroundColor
        ? css`
            background: ${typeof $props.backgroundColorAlpha === 'number'
              ? hexToRGBA((colourPalette as any)[$props.backgroundColor], $props.backgroundColorAlpha)
              : (colourPalette as any)[$props.backgroundColor]};
          `
        : ''}
      ${$props.borderWidth
        ? css`
            border-color: ${$props.borderColor
              ? (colourPalette as any)[$props.borderColor]
              : colourPalette.neutral900};
            border-style: solid;
          `
        : ''}
      ${$props.borderWidth ? getBorderWidth($props.borderWidth) : ''}
      ${$props.padding ? getPadding($props.padding) : ''}
      ${$props.shadow
        ? css`
            box-shadow: ${(shadow as any)[$props.shadow]};
          `
        : ''}
      ${$props.stretch ? 'height: 100%;' : ''}
      ${$props.fit ? `flex: ${$props.fit === 'content' ? '0 0 fit-content' : '1 1 auto'};` : ''}
      ${$props.noAutoMinSize
        ? $props.noAutoMinSize !== 'both'
          ? `min-${$props.noAutoMinSize}: 0;`
          : css`
              min-height: 0;
              min-width: 0;
            `
        : ''}
      ${$props.maxWidth ? `max-width: ${$props.maxWidth}px;` : ''}
    `}
  `,
};
