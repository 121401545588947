import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, renderBlocChild } from 'types/BLoCBase';
import { ManageProjectPermissionsModalTypes } from './ManageProjectPermissionsModal.types';
import { useForm, UseFormReturn } from 'react-hook-form';
import { $delete, $get, $post } from 'services/api';
import { finalize, shareReplay, startWith, switchMap } from 'rxjs';
import { snackbarV2 } from 'services/snackbarV2';
import { Icon } from 'components/Icon/Icon';

type State = {
  refetchDate?: Date;
  loading?: 'addingUser' | 'removingUser' | 'loaded';
  customerUsers: ManageProjectPermissionsModalTypes.User[];
};

class BLoC extends BLoCBase<State> {
  public $loading = this.$getState('loading');
  public $customerUsers = this.$getState('customerUsers');
  public $projectUsers = this.$getState('refetchDate')
    .pipe(
      switchMap(() =>
        $get<ManageProjectPermissionsModalTypes.User[]>(
          `permissions/projects/${this.projectId}/users/enabled`
        ).pipe(startWith(null))
      )
    )
    .pipe(shareReplay());

  constructor(
    public readonly form: UseFormReturn<ManageProjectPermissionsModalTypes.FormValues>,
    public readonly projectId: ManageProjectPermissionsModalTypes.ManageProjectPermissionsModalBLoCProps['projectId'],
    public readonly closeModal: ManageProjectPermissionsModalTypes.ManageProjectPermissionsModalBLoCProps['onClose']
  ) {
    super({ customerUsers: [] });
  }

  public addUserPermissionToProject = () => {
    this.setState('loading', 'addingUser');
    this.addSub(
      $post(`permissions/projects/${this.projectId}/users/store`, {
        user_id: this.form.getValues().user?.id,
      })
        .pipe(finalize(() => this.setState('loading', 'loaded')))
        .subscribe({
          next: () => {
            this.form.reset({ user: null });
            this.setState('refetchDate', new Date());
          },
          error: (err) =>
            snackbarV2({
              title: err?.message || 'An error ocurred. Try again later.',
              dismissable: true,
              leftItem: <Icon icon="alertOctagonSolid" color="rose500" />,
            }),
        }),
      'addUserPermissionToProject'
    );
  };

  public removeUserPermisionInProject = (user: ManageProjectPermissionsModalTypes.User) => {
    this.setState('loading', 'removingUser');
    this.addSub(
      $delete(`permissions/projects/${this.projectId}/users/${user.id}/revoke-access`)
        .pipe(finalize(() => this.setState('loading', 'loaded')))
        .subscribe({
          next: () => {
            this.setState('refetchDate', new Date());
            snackbarV2({
              title: 'User removed successfully.',
              dismissable: true,
              leftItem: <Icon icon="checkCircleSolid" color="green500" />,
            });
          },
          error: (err) =>
            snackbarV2({
              title: err?.message || 'An error ocurred. Try again later.',
              dismissable: true,
              leftItem: <Icon icon="alertOctagonSolid" color="rose500" />,
            }),
        }),
      'removeUserPermisionInProject'
    );
  };

  public getCustomerUsers = (value: string) => {
    this.addSub(
      $get<ManageProjectPermissionsModalTypes.User[]>(
        `permissions/projects/${this.projectId}/users/available`,
        {
          ...(!!value && { 'filter[name]': value }),
        }
      ).subscribe((data) => this.setState('customerUsers', data)),
      'getCustomerUsers'
    );
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useManageProjectPermissionsModalBLoC = () => useBLoC<BLoC>(Context);

export const ManageProjectPermissionsModalBLoC: React.FC<
  BLoCParams<BLoC, State> & ManageProjectPermissionsModalTypes.ManageProjectPermissionsModalBLoCProps
> = ({ children, projectId, onClose }) => {
  const form = useForm({
    defaultValues: new ManageProjectPermissionsModalTypes.FormValues(),
    mode: 'all',
  });
  const bloc = useInitBloc(() => new BLoC(form, projectId, onClose));
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
