import React, { useMemo } from 'react';
import { useClassNames } from 'hooks/useClassNames';
import { BreadCrumb, BreadCrumbProps } from 'primereact/breadcrumb';
import { PBreadCrumbStyle as S } from './PBreadCrumb.style';
import { Icon } from 'components/Icon/Icon';

export const PBreadCrumb = React.forwardRef<BreadCrumb, BreadCrumbProps & { homeHidden: boolean }>(
  (props, ref) => {
    const cleanProps = useMemo(() => (({ homeHidden, ...o }: any) => o)(props), [props]);
    const classes = useClassNames({ 'breadcrumb-home-hidden': props.homeHidden }, props.className);
    return (
      <S.BreadCrumb
        {...cleanProps}
        separatorIcon={<Icon icon="chevronRightLine" size={14} />}
        className={classes}
        ref={ref}
      />
    );
  }
);
