import { Box } from 'components/layouts/primitives/Box/Box';
import styled from 'styled-components';
import { fontSize } from 'styles/styles';

const sizes = {
  xs: {
    size: '16px',
    fontSize: '6px',
  },
  sm: { size: '20px', fontSize: '8px' },
  md: { size: '24px', fontSize: '10px' },
  lg: { size: '32px', fontSize: fontSize.fontSizeXS },
  xl: { size: '40px', fontSize: fontSize.fontSizeMD },
};

export const AvatarStyle = {
  Container: styled(Box)`
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
    }
    ${Object.keys(sizes).map((key) => {
      const sizeKey = key as keyof typeof sizes;
      return `
        &.${sizeKey} {
          height: ${sizes[sizeKey].size};
          width: ${sizes[sizeKey].size};
          .avatar-text {
            font-size: ${sizes[sizeKey].fontSize};
          }
        }
      `;
    })}
  `,
};
