import { Box } from 'components/layouts/primitives/Box/Box';
import { Scroller } from 'components/layouts/primitives/Scroller/Scroller';
import styled from 'styled-components';

export const ManageProjectPermissionsUserListStyle = {
  Scroller: styled(Scroller)`
    max-height: 240px;
  `,
  EmptyBox: styled(Box)`
    height: 120px;
  `,
};
