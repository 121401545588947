import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCParams, renderBlocChild, IBLoCInitialisable, BLoCWithModalsBase } from 'types/BLoCBase';
import React from 'react';
import { catchError, combineLatest, first, map, of, shareReplay, switchMap } from 'rxjs';
import { $get, $patch, $put } from 'services/api';
import { useObservableState } from 'observable-hooks';
import { format } from 'date-fns';
import { toast } from 'services/toast';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import {
  useImportModalCsvBLoC,
  TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID,
  TC_IMPORT_DATE_RESPONSE_TYPE_ID,
  TC_IMPORT_NPS_RESPONSE_TYPE_ID,
  TC_IMPORT_TEXT_RESPONSE_TYPE_ID,
} from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { SurveyQuestion } from 'types/SurveyQuestions';
import { ImportModalTypes } from 'components/modals/ImportModal/ImportModal.types';
import { MessageProps } from 'primereact/message';
import { getUserStore } from 'stores/user.store';
import { MapStepStyle as S } from './MapStep.style';
import { Icon } from 'components/Icon/Icon';

const TIMEZONES = [
  { value: 'America/Puerto_Rico', name: 'Puerto Rico (Atlantic)' },
  { value: 'America/New_York', name: 'New York (Eastern)' },
  { value: 'America/Chicago', name: 'Chicago (Central)' },
  { value: 'America/Denver', name: 'Denver (Mountain)' },
  { value: 'America/Phoenix', name: 'Phoenix (MST)' },
  { value: 'America/Los_Angeles', name: 'Los Angeles (Pacific)' },
  { value: 'America/Anchorage', name: 'Anchorage (Alaska)' },
  { value: 'Pacific/Honolulu', name: 'Honolulu (Hawaii)' },
  { value: 'America/Adak', name: 'Adak' },
  { value: 'America/Anchorage', name: 'Anchorage' },
  { value: 'America/Anguilla', name: 'Anguilla' },
  { value: 'America/Antigua', name: 'Antigua' },
  { value: 'America/Araguaina', name: 'Araguaina' },
  { value: 'America/Aruba', name: 'Aruba' },
  { value: 'America/Asuncion', name: 'Asuncion' },
  { value: 'America/Atikokan', name: 'Atikokan' },
  { value: 'America/Atka', name: 'Atka' },
  { value: 'America/Bahia', name: 'Bahia' },
  { value: 'America/Barbados', name: 'Barbados' },
  { value: 'America/Belem', name: 'Belem' },
  { value: 'America/Belize', name: 'Belize' },
  { value: 'America/Blanc-Sablon', name: 'Blanc-Sablon' },
  { value: 'America/Boa_Vista', name: 'Boa Vista' },
  { value: 'America/Bogota', name: 'Bogota' },
  { value: 'America/Boise', name: 'Boise' },
  { value: 'America/Buenos_Aires', name: 'Buenos Aires' },
  { value: 'America/Cambridge_Bay', name: 'Cambridge Bay' },
  { value: 'America/Campo_Grande', name: 'Campo Grande' },
  { value: 'America/Cancun', name: 'Cancun' },
  { value: 'America/Caracas', name: 'Caracas' },
  { value: 'America/Catamarca', name: 'Catamarca' },
  { value: 'America/Cayenne', name: 'Cayenne' },
  { value: 'America/Cayman', name: 'Cayman' },
  { value: 'America/Chicago', name: 'Chicago' },
  { value: 'America/Chihuahua', name: 'Chihuahua' },
  { value: 'America/Coral_Harbour', name: 'Coral Harbour' },
  { value: 'America/Cordoba', name: 'Cordoba' },
  { value: 'America/Costa_Rica', name: 'Costa Rica' },
  { value: 'America/Cuiaba', name: 'Cuiaba' },
  { value: 'America/Curacao', name: 'Curacao' },
  { value: 'America/Danmarkshavn', name: 'Danmarkshavn' },
  { value: 'America/Dawson', name: 'Dawson' },
  { value: 'America/Dawson_Creek', name: 'Dawson Creek' },
  { value: 'America/Denver', name: 'Denver' },
  { value: 'America/Detroit', name: 'Detroit' },
  { value: 'America/Dominica', name: 'Dominica' },
  { value: 'America/Edmonton', name: 'Edmonton' },
  { value: 'America/Eirunepe', name: 'Eirunepe' },
  { value: 'America/El_Salvador', name: 'El Salvador' },
  { value: 'America/Ensenada', name: 'Ensenada' },
  { value: 'America/Fortaleza', name: 'Fortaleza' },
  { value: 'America/Fort_Wayne', name: 'Fort Wayne' },
  { value: 'America/Glace_Bay', name: 'Glace Bay' },
  { value: 'America/Godthab', name: 'Godthab' },
  { value: 'America/Goose_Bay', name: 'Goose Bay' },
  { value: 'America/Grand_Turk', name: 'Grand Turk' },
  { value: 'America/Grenada', name: 'Grenada' },
  { value: 'America/Guadeloupe', name: 'Guadeloupe' },
  { value: 'America/Guatemala', name: 'Guatemala' },
  { value: 'America/Guayaquil', name: 'Guayaquil' },
  { value: 'America/Guyana', name: 'Guyana' },
  { value: 'America/Halifax', name: 'Halifax' },
  { value: 'America/Havana', name: 'Havana' },
  { value: 'America/Hermosillo', name: 'Hermosillo' },
  { value: 'America/Indiana/Indianapolis', name: 'Indiana - Indianapolis' },
  { value: 'America/Indiana/Knox', name: 'Indiana - Knox' },
  { value: 'America/Indiana/Marengo', name: 'Indiana - Marengo' },
  { value: 'America/Indiana/Petersburg', name: 'Indiana - Petersburg' },
  { value: 'America/Indiana/Tell_City', name: 'Indiana - Tell City' },
  { value: 'America/Indiana/Vevay', name: 'Indiana - Vevay' },
  { value: 'America/Indiana/Vincennes', name: 'Indiana - Vincennes' },
  { value: 'America/Indiana/Winamac', name: 'Indiana - Winamac' },
  { value: 'America/Indianapolis', name: 'Indianapolis' },
  { value: 'America/Inuvik', name: 'Inuvik' },
  { value: 'America/Iqaluit', name: 'Iqaluit' },
  { value: 'America/Jamaica', name: 'Jamaica' },
  { value: 'America/Jujuy', name: 'Jujuy' },
  { value: 'America/Juneau', name: 'Juneau' },
  { value: 'America/Kentucky/Louisville', name: 'Kentucky - Louisville' },
  { value: 'America/Kentucky/Monticello', name: 'Kentucky - Monticello' },
  { value: 'America/Knox_IN', name: 'Knox IN' },
  { value: 'America/La_Paz', name: 'La Paz' },
  { value: 'America/Lima', name: 'Lima' },
  { value: 'America/Los_Angeles', name: 'Los Angeles' },
  { value: 'America/Louisville', name: 'Louisville' },
  { value: 'America/Maceio', name: 'Maceio' },
  { value: 'America/Managua', name: 'Managua' },
  { value: 'America/Manaus', name: 'Manaus' },
  { value: 'America/Marigot', name: 'Marigot' },
  { value: 'America/Martinique', name: 'Martinique' },
  { value: 'America/Matamoros', name: 'Matamoros' },
  { value: 'America/Mazatlan', name: 'Mazatlan' },
  { value: 'America/Mendoza', name: 'Mendoza' },
  { value: 'America/Menominee', name: 'Menominee' },
  { value: 'America/Merida', name: 'Merida' },
  { value: 'America/Mexico_City', name: 'Mexico City' },
  { value: 'America/Miquelon', name: 'Miquelon' },
  { value: 'America/Moncton', name: 'Moncton' },
  { value: 'America/Monterrey', name: 'Monterrey' },
  { value: 'America/Montevideo', name: 'Montevideo' },
  { value: 'America/Montreal', name: 'Montreal' },
  { value: 'America/Montserrat', name: 'Montserrat' },
  { value: 'America/Nassau', name: 'Nassau' },
  { value: 'America/New_York', name: 'New York' },
  { value: 'America/Nipigon', name: 'Nipigon' },
  { value: 'America/Nome', name: 'Nome' },
  { value: 'America/Noronha', name: 'Noronha' },
  { value: 'America/North_Dakota/Center', name: 'North Dakota - Center' },
  { value: 'America/North_Dakota/New_Salem', name: 'North Dakota - New Salem' },
  { value: 'America/Ojinaga', name: 'Ojinaga' },
  { value: 'America/Panama', name: 'Panama' },
  { value: 'America/Pangnirtung', name: 'Pangnirtung' },
  { value: 'America/Paramaribo', name: 'Paramaribo' },
  { value: 'America/Phoenix', name: 'Phoenix' },
  { value: 'America/Port-au-Prince', name: 'Port-au-Prince' },
  { value: 'America/Porto_Acre', name: 'Porto Acre' },
  { value: 'America/Port_of_Spain', name: 'Port of Spain' },
  { value: 'America/Porto_Velho', name: 'Porto Velho' },
  { value: 'America/Puerto_Rico', name: 'Puerto Rico' },
  { value: 'America/Rainy_River', name: 'Rainy River' },
  { value: 'America/Rankin_Inlet', name: 'Rankin Inlet' },
  { value: 'America/Recife', name: 'Recife' },
  { value: 'America/Regina', name: 'Regina' },
  { value: 'America/Resolute', name: 'Resolute' },
  { value: 'America/Rio_Branco', name: 'Rio Branco' },
  { value: 'America/Rosario', name: 'Rosario' },
  { value: 'America/Santa_Isabel', name: 'Santa Isabel' },
  { value: 'America/Santarem', name: 'Santarem' },
  { value: 'America/Santiago', name: 'Santiago' },
  { value: 'America/Santo_Domingo', name: 'Santo Domingo' },
  { value: 'America/Sao_Paulo', name: 'Sao Paulo' },
  { value: 'America/Scoresbysund', name: 'Scoresbysund' },
  { value: 'America/Shiprock', name: 'Shiprock' },
  { value: 'America/St_Barthelemy', name: 'St Barthelemy' },
  { value: 'America/St_Johns', name: 'St Johns' },
  { value: 'America/St_Kitts', name: 'St Kitts' },
  { value: 'America/St_Lucia', name: 'St Lucia' },
  { value: 'America/St_Thomas', name: 'St Thomas' },
  { value: 'America/St_Vincent', name: 'St Vincent' },
  { value: 'America/Swift_Current', name: 'Swift Current' },
  { value: 'America/Tegucigalpa', name: 'Tegucigalpa' },
  { value: 'America/Thule', name: 'Thule' },
  { value: 'America/Thunder_Bay', name: 'Thunder Bay' },
  { value: 'America/Tijuana', name: 'Tijuana' },
  { value: 'America/Toronto', name: 'Toronto' },
  { value: 'America/Tortola', name: 'Tortola' },
  { value: 'America/Vancouver', name: 'Vancouver' },
  { value: 'America/Virgin', name: 'Virgin' },
  { value: 'America/Whitehorse', name: 'Whitehorse' },
  { value: 'America/Winnipeg', name: 'Winnipeg' },
  { value: 'America/Yakutat', name: 'Yakutat' },
  { value: 'America/Yellowknife', name: 'Yellowknife' },
  { value: 'Europe/Amsterdam', name: 'Amsterdam' },
  { value: 'Europe/Andorra', name: 'Andorra' },
  { value: 'Europe/Athens', name: 'Athens' },
  { value: 'Europe/Belfast', name: 'Belfast' },
  { value: 'Europe/Belgrade', name: 'Belgrade' },
  { value: 'Europe/Berlin', name: 'Berlin' },
  { value: 'Europe/Bratislava', name: 'Bratislava' },
  { value: 'Europe/Brussels', name: 'Brussels' },
  { value: 'Europe/Bucharest', name: 'Bucharest' },
  { value: 'Europe/Budapest', name: 'Budapest' },
  { value: 'Europe/Chisinau', name: 'Chisinau' },
  { value: 'Europe/Copenhagen', name: 'Copenhagen' },
  { value: 'Europe/Dublin', name: 'Dublin' },
  { value: 'Europe/Gibraltar', name: 'Gibraltar' },
  { value: 'Europe/Guernsey', name: 'Guernsey' },
  { value: 'Europe/Helsinki', name: 'Helsinki' },
  { value: 'Europe/Isle_of_Man', name: 'Isle of Man' },
  { value: 'Europe/Istanbul', name: 'Istanbul' },
  { value: 'Europe/Jersey', name: 'Jersey' },
  { value: 'Europe/Kaliningrad', name: 'Kaliningrad' },
  { value: 'Europe/Kiev', name: 'Kiev' },
  { value: 'Europe/Lisbon', name: 'Lisbon' },
  { value: 'Europe/Ljubljana', name: 'Ljubljana' },
  { value: 'Europe/London', name: 'London' },
  { value: 'Europe/Luxembourg', name: 'Luxembourg' },
  { value: 'Europe/Madrid', name: 'Madrid' },
  { value: 'Europe/Malta', name: 'Malta' },
  { value: 'Europe/Mariehamn', name: 'Mariehamn' },
  { value: 'Europe/Minsk', name: 'Minsk' },
  { value: 'Europe/Monaco', name: 'Monaco' },
  { value: 'Europe/Moscow', name: 'Moscow' },
  { value: 'Europe/Nicosia', name: 'Nicosia' },
  { value: 'Europe/Oslo', name: 'Oslo' },
  { value: 'Europe/Paris', name: 'Paris' },
  { value: 'Europe/Podgorica', name: 'Podgorica' },
  { value: 'Europe/Prague', name: 'Prague' },
  { value: 'Europe/Riga', name: 'Riga' },
  { value: 'Europe/Rome', name: 'Rome' },
  { value: 'Europe/Samara', name: 'Samara' },
  { value: 'Europe/San_Marino', name: 'San Marino' },
  { value: 'Europe/Sarajevo', name: 'Sarajevo' },
  { value: 'Europe/Simferopol', name: 'Simferopol' },
  { value: 'Europe/Skopje', name: 'Skopje' },
  { value: 'Europe/Sofia', name: 'Sofia' },
  { value: 'Europe/Stockholm', name: 'Stockholm' },
  { value: 'Europe/Tallinn', name: 'Tallinn' },
  { value: 'Europe/Tirane', name: 'Tirane' },
  { value: 'Europe/Tiraspol', name: 'Tiraspol' },
  { value: 'Europe/Uzhgorod', name: 'Uzhgorod' },
  { value: 'Europe/Vaduz', name: 'Vaduz' },
  { value: 'Europe/Vatican', name: 'Vatican' },
  { value: 'Europe/Vienna', name: 'Vienna' },
  { value: 'Europe/Vilnius', name: 'Vilnius' },
  { value: 'Europe/Volgograd', name: 'Volgograd' },
  { value: 'Europe/Warsaw', name: 'Warsaw' },
  { value: 'Europe/Zagreb', name: 'Zagreb' },
  { value: 'Europe/Zaporozhye', name: 'Zaporozhye' },
  { value: 'Europe/Zurich', name: 'Zurich' },
  { value: 'Asia/Aden', name: 'Aden' },
  { value: 'Asia/Almaty', name: 'Almaty' },
  { value: 'Asia/Amman', name: 'Amman' },
  { value: 'Asia/Anadyr', name: 'Anadyr' },
  { value: 'Asia/Aqtau', name: 'Aqtau' },
  { value: 'Asia/Aqtobe', name: 'Aqtobe' },
  { value: 'Asia/Ashgabat', name: 'Ashgabat' },
  { value: 'Asia/Ashkhabad', name: 'Ashkhabad' },
  { value: 'Asia/Baghdad', name: 'Baghdad' },
  { value: 'Asia/Bahrain', name: 'Bahrain' },
  { value: 'Asia/Baku', name: 'Baku' },
  { value: 'Asia/Bangkok', name: 'Bangkok' },
  { value: 'Asia/Beirut', name: 'Beirut' },
  { value: 'Asia/Bishkek', name: 'Bishkek' },
  { value: 'Asia/Brunei', name: 'Brunei' },
  { value: 'Asia/Calcutta', name: 'Calcutta' },
  { value: 'Asia/Choibalsan', name: 'Choibalsan' },
  { value: 'Asia/Chongqing', name: 'Chongqing' },
  { value: 'Asia/Chungking', name: 'Chungking' },
  { value: 'Asia/Colombo', name: 'Colombo' },
  { value: 'Asia/Dacca', name: 'Dacca' },
  { value: 'Asia/Damascus', name: 'Damascus' },
  { value: 'Asia/Dhaka', name: 'Dhaka' },
  { value: 'Asia/Dili', name: 'Dili' },
  { value: 'Asia/Dubai', name: 'Dubai' },
  { value: 'Asia/Dushanbe', name: 'Dushanbe' },
  { value: 'Asia/Gaza', name: 'Gaza' },
  { value: 'Asia/Harbin', name: 'Harbin' },
  { value: 'Asia/Ho_Chi_Minh', name: 'Ho Chi Minh' },
  { value: 'Asia/Hong_Kong', name: 'Hong Kong' },
  { value: 'Asia/Hovd', name: 'Hovd' },
  { value: 'Asia/Irkutsk', name: 'Irkutsk' },
  { value: 'Asia/Istanbul', name: 'Istanbul' },
  { value: 'Asia/Jakarta', name: 'Jakarta' },
  { value: 'Asia/Jayapura', name: 'Jayapura' },
  { value: 'Asia/Jerusalem', name: 'Jerusalem' },
  { value: 'Asia/Kabul', name: 'Kabul' },
  { value: 'Asia/Kamchatka', name: 'Kamchatka' },
  { value: 'Asia/Karachi', name: 'Karachi' },
  { value: 'Asia/Kashgar', name: 'Kashgar' },
  { value: 'Asia/Kathmandu', name: 'Kathmandu' },
  { value: 'Asia/Katmandu', name: 'Katmandu' },
  { value: 'Asia/Kolkata', name: 'Kolkata' },
  { value: 'Asia/Krasnoyarsk', name: 'Krasnoyarsk' },
  { value: 'Asia/Kuala_Lumpur', name: 'Kuala Lumpur' },
  { value: 'Asia/Kuching', name: 'Kuching' },
  { value: 'Asia/Kuwait', name: 'Kuwait' },
  { value: 'Asia/Macao', name: 'Macao' },
  { value: 'Asia/Macau', name: 'Macau' },
  { value: 'Asia/Magadan', name: 'Magadan' },
  { value: 'Asia/Makassar', name: 'Makassar' },
  { value: 'Asia/Manila', name: 'Manila' },
  { value: 'Asia/Muscat', name: 'Muscat' },
  { value: 'Asia/Nicosia', name: 'Nicosia' },
  { value: 'Asia/Novokuznetsk', name: 'Novokuznetsk' },
  { value: 'Asia/Novosibirsk', name: 'Novosibirsk' },
  { value: 'Asia/Omsk', name: 'Omsk' },
  { value: 'Asia/Oral', name: 'Oral' },
  { value: 'Asia/Phnom_Penh', name: 'Phnom Penh' },
  { value: 'Asia/Pontianak', name: 'Pontianak' },
  { value: 'Asia/Pyongyang', name: 'Pyongyang' },
  { value: 'Asia/Qatar', name: 'Qatar' },
  { value: 'Asia/Qyzylorda', name: 'Qyzylorda' },
  { value: 'Asia/Rangoon', name: 'Rangoon' },
  { value: 'Asia/Riyadh', name: 'Riyadh' },
  { value: 'Asia/Saigon', name: 'Saigon' },
  { value: 'Asia/Sakhalin', name: 'Sakhalin' },
  { value: 'Asia/Samarkand', name: 'Samarkand' },
  { value: 'Asia/Seoul', name: 'Seoul' },
  { value: 'Asia/Shanghai', name: 'Shanghai' },
  { value: 'Asia/Singapore', name: 'Singapore' },
  { value: 'Asia/Taipei', name: 'Taipei' },
  { value: 'Asia/Tashkent', name: 'Tashkent' },
  { value: 'Asia/Tbilisi', name: 'Tbilisi' },
  { value: 'Asia/Tehran', name: 'Tehran' },
  { value: 'Asia/Tel_Aviv', name: 'Tel Aviv' },
  { value: 'Asia/Thimbu', name: 'Thimbu' },
  { value: 'Asia/Thimphu', name: 'Thimphu' },
  { value: 'Asia/Tokyo', name: 'Tokyo' },
  { value: 'Asia/Ujung_Pandang', name: 'Ujung Pandang' },
  { value: 'Asia/Ulaanbaatar', name: 'Ulaanbaatar' },
  { value: 'Asia/Ulan_Bator', name: 'Ulan Bator' },
  { value: 'Asia/Urumqi', name: 'Urumqi' },
  { value: 'Asia/Vientiane', name: 'Vientiane' },
  { value: 'Asia/Vladivostok', name: 'Vladivostok' },
  { value: 'Asia/Yakutsk', name: 'Yakutsk' },
  { value: 'Asia/Yekaterinburg', name: 'Yekaterinburg' },
  { value: 'Asia/Yerevan', name: 'Yerevan' },
  { value: 'Africa/Abidjan', name: 'Abidjan' },
  { value: 'Africa/Accra', name: 'Accra' },
  { value: 'Africa/Addis_Ababa', name: 'Addis Ababa' },
  { value: 'Africa/Algiers', name: 'Algiers' },
  { value: 'Africa/Asmara', name: 'Asmara' },
  { value: 'Africa/Asmera', name: 'Asmera' },
  { value: 'Africa/Bamako', name: 'Bamako' },
  { value: 'Africa/Bangui', name: 'Bangui' },
  { value: 'Africa/Banjul', name: 'Banjul' },
  { value: 'Africa/Bissau', name: 'Bissau' },
  { value: 'Africa/Blantyre', name: 'Blantyre' },
  { value: 'Africa/Brazzaville', name: 'Brazzaville' },
  { value: 'Africa/Bujumbura', name: 'Bujumbura' },
  { value: 'Africa/Cairo', name: 'Cairo' },
  { value: 'Africa/Casablanca', name: 'Casablanca' },
  { value: 'Africa/Ceuta', name: 'Ceuta' },
  { value: 'Africa/Conakry', name: 'Conakry' },
  { value: 'Africa/Dakar', name: 'Dakar' },
  { value: 'Africa/Dar_es_Salaam', name: 'Dar es Salaam' },
  { value: 'Africa/Djibouti', name: 'Djibouti' },
  { value: 'Africa/Douala', name: 'Douala' },
  { value: 'Africa/El_Aaiun', name: 'El Aaiun' },
  { value: 'Africa/Freetown', name: 'Freetown' },
  { value: 'Africa/Gaborone', name: 'Gaborone' },
  { value: 'Africa/Harare', name: 'Harare' },
  { value: 'Africa/Johannesburg', name: 'Johannesburg' },
  { value: 'Africa/Kampala', name: 'Kampala' },
  { value: 'Africa/Khartoum', name: 'Khartoum' },
  { value: 'Africa/Kigali', name: 'Kigali' },
  { value: 'Africa/Kinshasa', name: 'Kinshasa' },
  { value: 'Africa/Lagos', name: 'Lagos' },
  { value: 'Africa/Libreville', name: 'Libreville' },
  { value: 'Africa/Lome', name: 'Lome' },
  { value: 'Africa/Luanda', name: 'Luanda' },
  { value: 'Africa/Lubumbashi', name: 'Lubumbashi' },
  { value: 'Africa/Lusaka', name: 'Lusaka' },
  { value: 'Africa/Malabo', name: 'Malabo' },
  { value: 'Africa/Maputo', name: 'Maputo' },
  { value: 'Africa/Maseru', name: 'Maseru' },
  { value: 'Africa/Mbabane', name: 'Mbabane' },
  { value: 'Africa/Mogadishu', name: 'Mogadishu' },
  { value: 'Africa/Monrovia', name: 'Monrovia' },
  { value: 'Africa/Nairobi', name: 'Nairobi' },
  { value: 'Africa/Ndjamena', name: 'Ndjamena' },
  { value: 'Africa/Niamey', name: 'Niamey' },
  { value: 'Africa/Nouakchott', name: 'Nouakchott' },
  { value: 'Africa/Ouagadougou', name: 'Ouagadougou' },
  { value: 'Africa/Porto-Novo', name: 'Porto-Novo' },
  { value: 'Africa/Sao_Tome', name: 'Sao Tome' },
  { value: 'Africa/Timbuktu', name: 'Timbuktu' },
  { value: 'Africa/Tripoli', name: 'Tripoli' },
  { value: 'Africa/Tunis', name: 'Tunis' },
  { value: 'Africa/Windhoek', name: 'Windhoek' },
  { value: 'Australia/ACT', name: 'ACT' },
  { value: 'Australia/Adelaide', name: 'Adelaide' },
  { value: 'Australia/Brisbane', name: 'Brisbane' },
  { value: 'Australia/Broken_Hill', name: 'Broken Hill' },
  { value: 'Australia/Canberra', name: 'Canberra' },
  { value: 'Australia/Currie', name: 'Currie' },
  { value: 'Australia/Darwin', name: 'Darwin' },
  { value: 'Australia/Eucla', name: 'Eucla' },
  { value: 'Australia/Hobart', name: 'Hobart' },
  { value: 'Australia/LHI', name: 'LHI' },
  { value: 'Australia/Lindeman', name: 'Lindeman' },
  { value: 'Australia/Lord_Howe', name: 'Lord Howe' },
  { value: 'Australia/Melbourne', name: 'Melbourne' },
  { value: 'Australia/North', name: 'North' },
  { value: 'Australia/NSW', name: 'NSW' },
  { value: 'Australia/Perth', name: 'Perth' },
  { value: 'Australia/Queensland', name: 'Queensland' },
  { value: 'Australia/South', name: 'South' },
  { value: 'Australia/Sydney', name: 'Sydney' },
  { value: 'Australia/Tasmania', name: 'Tasmania' },
  { value: 'Australia/Victoria', name: 'Victoria' },
  { value: 'Australia/West', name: 'West' },
  { value: 'Australia/Yancowinna', name: 'Yancowinna' },
  { value: 'Indian/Antananarivo', name: 'Antananarivo' },
  { value: 'Indian/Chagos', name: 'Chagos' },
  { value: 'Indian/Christmas', name: 'Christmas' },
  { value: 'Indian/Cocos', name: 'Cocos' },
  { value: 'Indian/Comoro', name: 'Comoro' },
  { value: 'Indian/Kerguelen', name: 'Kerguelen' },
  { value: 'Indian/Mahe', name: 'Mahe' },
  { value: 'Indian/Maldives', name: 'Maldives' },
  { value: 'Indian/Mauritius', name: 'Mauritius' },
  { value: 'Indian/Mayotte', name: 'Mayotte' },
  { value: 'Indian/Reunion', name: 'Reunion' },
  { value: 'Atlantic/Azores', name: 'Azores' },
  { value: 'Atlantic/Bermuda', name: 'Bermuda' },
  { value: 'Atlantic/Canary', name: 'Canary' },
  { value: 'Atlantic/Cape_Verde', name: 'Cape Verde' },
  { value: 'Atlantic/Faeroe', name: 'Faeroe' },
  { value: 'Atlantic/Faroe', name: 'Faroe' },
  { value: 'Atlantic/Jan_Mayen', name: 'Jan Mayen' },
  { value: 'Atlantic/Madeira', name: 'Madeira' },
  { value: 'Atlantic/Reykjavik', name: 'Reykjavik' },
  { value: 'Atlantic/South_Georgia', name: 'South Georgia' },
  { value: 'Atlantic/Stanley', name: 'Stanley' },
  { value: 'Atlantic/St_Helena', name: 'St Helena' },
  { value: 'Pacific/Apia', name: 'Apia' },
  { value: 'Pacific/Auckland', name: 'Auckland' },
  { value: 'Pacific/Chatham', name: 'Chatham' },
  { value: 'Pacific/Easter', name: 'Easter' },
  { value: 'Pacific/Efate', name: 'Efate' },
  { value: 'Pacific/Enderbury', name: 'Enderbury' },
  { value: 'Pacific/Fakaofo', name: 'Fakaofo' },
  { value: 'Pacific/Fiji', name: 'Fiji' },
  { value: 'Pacific/Funafuti', name: 'Funafuti' },
  { value: 'Pacific/Galapagos', name: 'Galapagos' },
  { value: 'Pacific/Gambier', name: 'Gambier' },
  { value: 'Pacific/Guadalcanal', name: 'Guadalcanal' },
  { value: 'Pacific/Guam', name: 'Guam' },
  { value: 'Pacific/Honolulu', name: 'Honolulu' },
  { value: 'Pacific/Johnston', name: 'Johnston' },
  { value: 'Pacific/Kiritimati', name: 'Kiritimati' },
  { value: 'Pacific/Kosrae', name: 'Kosrae' },
  { value: 'Pacific/Kwajalein', name: 'Kwajalein' },
  { value: 'Pacific/Majuro', name: 'Majuro' },
  { value: 'Pacific/Marquesas', name: 'Marquesas' },
  { value: 'Pacific/Midway', name: 'Midway' },
  { value: 'Pacific/Nauru', name: 'Nauru' },
  { value: 'Pacific/Niue', name: 'Niue' },
  { value: 'Pacific/Norfolk', name: 'Norfolk' },
  { value: 'Pacific/Noumea', name: 'Noumea' },
  { value: 'Pacific/Pago_Pago', name: 'Pago Pago' },
  { value: 'Pacific/Palau', name: 'Palau' },
  { value: 'Pacific/Pitcairn', name: 'Pitcairn' },
  { value: 'Pacific/Ponape', name: 'Ponape' },
  { value: 'Pacific/Port_Moresby', name: 'Port Moresby' },
  { value: 'Pacific/Rarotonga', name: 'Rarotonga' },
  { value: 'Pacific/Saipan', name: 'Saipan' },
  { value: 'Pacific/Samoa', name: 'Samoa' },
  { value: 'Pacific/Tahiti', name: 'Tahiti' },
  { value: 'Pacific/Tarawa', name: 'Tarawa' },
  { value: 'Pacific/Tongatapu', name: 'Tongatapu' },
  { value: 'Pacific/Truk', name: 'Truk' },
  { value: 'Pacific/Wake', name: 'Wake' },
  { value: 'Pacific/Wallis', name: 'Wallis' },
  { value: 'Pacific/Yap', name: 'Yap' },
  { value: 'Antarctica/Casey', name: 'Casey' },
  { value: 'Antarctica/Davis', name: 'Davis' },
  { value: 'Antarctica/DumontDUrville', name: 'DumontDUrville' },
  { value: 'Antarctica/Macquarie', name: 'Macquarie' },
  { value: 'Antarctica/Mawson', name: 'Mawson' },
  { value: 'Antarctica/McMurdo', name: 'McMurdo' },
  { value: 'Antarctica/Palmer', name: 'Palmer' },
  { value: 'Antarctica/Rothera', name: 'Rothera' },
  { value: 'Antarctica/South_Pole', name: 'South Pole' },
  { value: 'Antarctica/Syowa', name: 'Syowa' },
  { value: 'Antarctica/Vostok', name: 'Vostok' },
  { value: 'Arctic/Longyearbyen', name: 'Longyearbyen' },
];

const getDataTypesModalIds = async () => {
  try {
    const data = await localStorage.getItem('first-time-data-type-modal');
    const parse = JSON.parse(data || '');
    return parse && Array.isArray(parse) ? parse : [];
  } catch (err) {
    return [];
  }
};

const getColumnName = (column: ImportModalCsvTypes.IngestionColumn) =>
  (column.customName ? column.customName : column.metaName).trim();

type State = {
  columns: ImportModalCsvTypes.IngestionColumn[];
  loading?: boolean;
  draftData: {
    [key: string]: any;
  };
  customVariables: { id: string; name: string }[];
  questions: { id: string; name: string; type: string }[];
};

class BLoC
  extends BLoCWithModalsBase<
    State,
    {
      changeResponseDates: {
        id: number;
      };
      dataTypesModal: {};
    }
  >
  implements IBLoCInitialisable
{
  public $columns = this.$getState('columns');
  public $loading = this.$getState('loading');
  public $responseDataTypes = $get<{ id: number; name: string }[]>('response-data-types').pipe(
    catchError(() => []),
    shareReplay()
  );
  public $customVariables = this.$getState('customVariables');
  public $questions = this.$getState('questions');
  public $questionsText = this.$questions.pipe(map((list) => [...list.filter((a) => a?.type === 'text')]));
  public $questionsNps = this.$questions.pipe(map((list) => [...list.filter((a) => a?.type === 'nps')]));
  public $dateFieldCount = this.$columns.pipe(
    map(
      (cols) =>
        cols.filter((col) => col.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID && !col.ignoreColumn)
          .length
    )
  );

  public $selectedVars = this.$columns.pipe(
    map((columns) => {
      let questions: string[] = [];
      let variables: string[] = [];
      columns.forEach((col) => {
        if (
          [TC_IMPORT_TEXT_RESPONSE_TYPE_ID, TC_IMPORT_NPS_RESPONSE_TYPE_ID].includes(
            col.responseDataTypeId
          ) &&
          col.questionId &&
          col.questionId !== 'new'
        )
          questions = [...questions, col.questionId];
        if (
          col.responseDataTypeId === TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID &&
          col.customVariableId &&
          col.customVariableId !== 'new'
        )
          variables = [...variables, col.customVariableId];
      });
      return { questions, variables };
    })
  );

  public isNewColumn = (column: ImportModalCsvTypes.IngestionColumn) =>
    !!(this.currentIngestion?.draft || {})[column.id]?.isNewColumn;

  public isMatchedCustomVariable = (column: ImportModalCsvTypes.IngestionColumn) =>
    this.hasCustomVars
      ? column.responseDataTypeId === TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID && column.customVariableId
      : column.responseDataTypeId === TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID;

  public isMatchedQuestion = (column: ImportModalCsvTypes.IngestionColumn) =>
    this.hasQuestions
      ? column.responseDataTypeId === TC_IMPORT_TEXT_RESPONSE_TYPE_ID && column.questionId
      : column.responseDataTypeId === TC_IMPORT_TEXT_RESPONSE_TYPE_ID;

  public isMatchedNpsQuestion = (column: ImportModalCsvTypes.IngestionColumn) =>
    this.hasNpsQuestions
      ? column.responseDataTypeId === TC_IMPORT_NPS_RESPONSE_TYPE_ID && column.questionId
      : column.responseDataTypeId === TC_IMPORT_NPS_RESPONSE_TYPE_ID;

  public isMatched = (column: ImportModalCsvTypes.IngestionColumn) => {
    return (
      this.isEmptyProject ||
      this.isMatchedCustomVariable(column) ||
      this.isMatchedQuestion(column) ||
      this.isMatchedNpsQuestion(column) ||
      this.isNewColumn(column)
    );
  };

  public $errors = combineLatest([this.$columns, this.$dateFieldCount]).pipe(
    map(([columns, dateFieldCount]) => {
      let message = !dateFieldCount ? '- You must assign a response date to continue.' : '';
      let count = 0;
      let ids: number[] = [];

      if (!dateFieldCount) count = count + 1;

      if (dateFieldCount > 1) count = count + dateFieldCount;

      columns
        .filter((c) => !c.ignoreColumn)
        .forEach((column) => {
          if (this.existName(column)) {
            count = count + 1;
            ids = [...ids, column.id];
          }

          if (
            !this.isEmptyProject &&
            !this.isMatched(column) &&
            column.responseDataTypeId !== TC_IMPORT_DATE_RESPONSE_TYPE_ID
          ) {
            count = count + 1;
            ids = [...ids, column.id];
          }
        });

      return {
        count,
        message,
        ids,
      };
    })
  );

  public showRenameAlert = (column: ImportModalCsvTypes.IngestionColumn) =>
    column.wasMappedColRenamed && !column.editing;

  public existName = (column: ImportModalCsvTypes.IngestionColumn) => {
    const columns = this.currentState('columns').filter((c) => !c.ignoreColumn && c.id !== column.id);
    const data = [...this.currentState('questions'), ...this.currentState('customVariables')].filter((d) =>
      [TC_IMPORT_TEXT_RESPONSE_TYPE_ID, TC_IMPORT_NPS_RESPONSE_TYPE_ID].includes(column.responseDataTypeId)
        ? d.id !== column.questionId
        : d.id !== column.customVariableId
    );
    return (
      columns.some((c) => getColumnName(c).trim() === getColumnName(column)) ||
      !!data.find((c) => c.name === getColumnName(column))
    );
  };

  public getAlertsByColumn = (column: ImportModalCsvTypes.IngestionColumn, dateFieldCount: number) => {
    let alerts: {
      title?: string;
      text?: string;
      severity?: MessageProps['severity'];
      isMatched?: boolean;
    }[] = [];
    const hasDateFieldDuplicated = dateFieldCount > 1;

    if (column.ignoreColumn) return alerts;

    if (this.existName(column)) {
      alerts.push({
        title: 'Name already used',
        text: 'This name already seems to exist. Please rename to something unique to continue.',
        severity: 'warn',
      });
    }

    if (column.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID) {
      alerts.push({
        title: hasDateFieldDuplicated ? 'You can only have one response date' : 'This is your response date',
        text: hasDateFieldDuplicated
          ? 'We’ve detected multiple response dates in your file. Change the data type to Custom variable for all other columns to continue.'
          : 'You must have a response date. You can only have one response date per file.',
        severity: hasDateFieldDuplicated ? 'warn' : 'info',
      });
    }

    if (alerts.length) return alerts;

    if (!this.isEmptyProject) {
      if (this.isMatched(column) && !this.showRenameAlert(column)) {
        alerts.push({ isMatched: true });
      } else {
        if (column.wasMappedColRenamed) {
          alerts.push({
            title: 'Renamed in the previous upload',
            text: 'Confirm a column to merge with using the "Map to" dropdown below the column name on the left hand side, or select "Create new column".',
            severity: 'warn',
          });
        } else {
          alerts.push({
            title: 'No match found',
            text: 'Select an existing column to merge with using the "Map to" dropdown below the column name on the left hand side, or "Create new column".',
            severity: 'warn',
          });
        }
      }
    }
    return alerts;
  };

  public $hasDateField = this.$columns.pipe(
    map((cols) => !!cols.find((col) => col.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID))
  );

  public $hasDateFieldDuplicated = this.$columns.pipe(
    map(
      (cols) =>
        cols.filter((col) => col.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID && !col.ignoreColumn)
          .length > 1
    )
  );

  public $nohasDateField = this.$columns.pipe(
    map(
      (cols) =>
        !cols.filter((col) => col.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID && !col.ignoreColumn)
          .length
    )
  );

  public get error() {
    return this.currentIngestion?.error;
  }

  public get timezones() {
    return TIMEZONES.map((t) => ({ id: t.value, name: `${t.name} (${t.value})` })).sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
  }

  public get currentDate() {
    return {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      date: format(new Date(), 'MM/dd/yyyy'),
    };
  }

  public $disableNextButton = combineLatest([this.$errors, this.$loading]).pipe(
    map(([errors, loading]) => !!errors.count || loading)
  );

  public openResponseDateModal = (id: number) => this._openModal('changeResponseDates', { id });

  public closeResponseDateModal = () => this._closeModal();

  public closeDataTypesModal = async () => {
    this._closeModal();
    const ids = await getDataTypesModalIds();
    if (this.userId && !ids.includes(this.userId))
      localStorage.setItem('first-time-data-type-modal', JSON.stringify([...ids, this.userId]));
  };

  constructor(
    public readonly currentIngestion: ImportModalCsvTypes.Ingestion | undefined,
    public readonly currentIngestionColumns: ImportModalCsvTypes.IngestionColumn[],
    public readonly setIngestion: (ingestion: ImportModalCsvTypes.Ingestion) => void,
    public readonly setIngestionColumns: (ingestion: ImportModalCsvTypes.IngestionColumn[]) => void,
    public readonly setSelectedColumnIds: (columns: number[]) => void,
    public readonly changeStep: (step: ImportModalCsvTypes.IngestionSteps) => void,
    public readonly isEmptyProject: boolean,
    public readonly userId?: number,
    public readonly project?: ImportModalTypes.Project
  ) {
    super({ columns: [], draftData: {}, customVariables: [], questions: [] });
  }

  public onInit = async () => {
    if (this.currentIngestion && this.currentIngestionColumns.length) {
      if (this.hasCustomVars) this.getCustomVariables();
      if (this.hasQuestions) this.getQuestions();
      this.formatData(this.currentIngestion, this.currentIngestionColumns);
    }
    const data = await getDataTypesModalIds();
    if (this.userId && !data.includes(this.userId)) this._openModal('dataTypesModal', {});
  };

  public get hasQuestions() {
    return !!this.project?.hasQuestions;
  }

  public get hasCustomVars() {
    return !!this.project?.hasCustomVars;
  }

  public get hasNpsQuestions() {
    return !!this.hasQuestions && !!this.currentState('questions').filter((a) => a?.type === 'nps').length;
  }

  public formatData = (
    ingestion: ImportModalCsvTypes.Ingestion,
    columns: ImportModalCsvTypes.IngestionColumn[]
  ) => {
    this.setStates({
      draftData: ingestion.draft,
      columns: columns
        .map((col) => ({
          ...col,
          questionId:
            [TC_IMPORT_TEXT_RESPONSE_TYPE_ID, TC_IMPORT_NPS_RESPONSE_TYPE_ID].includes(
              col.responseDataTypeId
            ) && this.isNewColumn(col)
              ? 'new'
              : col.questionId,
          customVariableId:
            col.responseDataTypeId === TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID && this.isNewColumn(col)
              ? 'new'
              : col.customVariableId,
        }))
        .slice(),
      /**
       * @see https://thethinkingstudio.atlassian.net/browse/CE-10022
       */
      // .sort((a, b) =>
      //   a.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID && b.responseDataTypeId !== TC_IMPORT_DATE_RESPONSE_TYPE_ID
      //     ? -1
      //     : 1
      // )
      // .sort((a) => (!this.isMatched(a) && a.responseDataTypeId !== TC_IMPORT_DATE_RESPONSE_TYPE_ID ? -1 : 1)),
    });
  };

  public getQuestions = () => {
    this.addSub(
      $get<SurveyQuestion[]>(`questions`, {
        projectId: this.project?.id,
      })
        .pipe(
          map((list) => [
            { id: 'new', name: 'Create new column', type: 'auto' },
            { id: 'divider', name: 'EXISTING COLUMNS', type: 'auto' },
            ...list
              .filter((a) => a?.type === 'text' || a?.type === 'nps')
              .map(({ cleanTitle, id, type }) => ({
                id,
                name: cleanTitle || '',
                type,
              })),
          ]),
          catchError(() => []),
          shareReplay()
        )
        .subscribe((data) => this.setState('questions', data))
    );
  };

  public getCustomVariables = () =>
    this.addSub(
      $get<{ id: string; name: string }[]>(`ingestions/${this.project?.id}/vars`)
        .pipe(
          map((list) => [
            { id: 'new', name: 'Create new column' },
            { id: 'divider', name: 'EXISTING COLUMNS' },
            ...list,
          ]),
          catchError(() => []),
          shareReplay()
        )
        .subscribe((data) => this.setState('customVariables', data))
    );

  public changeResponseDates = (id: number) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      return tempColumns.map((column) =>
        column.id === id
          ? { ...column, responseDataTypeId: TC_IMPORT_DATE_RESPONSE_TYPE_ID, editing: null }
          : {
              ...column,
              editing: null,
              responseDataTypeId:
                column.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID
                  ? TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID
                  : column.responseDataTypeId,
            }
      );
    });
    this.closeResponseDateModal();
  };

  public showMapToDropdown = () => !this.isEmptyProject;

  public handleChangeResponseType = (id: number, value: number, hasDateField?: boolean) => {
    if (hasDateField && value === TC_IMPORT_DATE_RESPONSE_TYPE_ID) {
      this.openResponseDateModal(id);
    } else {
      this.mutateState('columns', (prevState) => {
        let tempColumns = [...prevState];
        const i = tempColumns.findIndex((ing) => ing.id === id);
        tempColumns[i].responseDataTypeId = value;
        tempColumns[i].editing = null;
        return tempColumns;
      });
    }
  };

  public handleChangeIgnoreColumn = (id: number, value: boolean) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].ignoreColumn = !value;
      return tempColumns;
    });
  };

  public isDisabledCustomName = (column: ImportModalCsvTypes.IngestionColumn) =>
    column.responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID ||
    (!this.isEmptyProject &&
      (column.responseDataTypeId === TC_IMPORT_TEXT_RESPONSE_TYPE_ID
        ? column.questionId !== 'new' && this.hasQuestions
        : column.responseDataTypeId === TC_IMPORT_NPS_RESPONSE_TYPE_ID
        ? column.questionId !== 'new' && this.hasNpsQuestions
        : column.customVariableId !== 'new' && this.hasCustomVars));

  public handleChangeCustomName = (id: number, value: string) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].customName = value;
      return tempColumns;
    });
  };

  public handleChangeTimezone = (id: number, value: string) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].extraInfo.timezone = value;
      return tempColumns;
    });
  };

  public handleChangeMonthFirst = (id: number, value: boolean) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].extraInfo.monthFirst = value;
      return tempColumns;
    });
  };

  public updateDraftId = (id: number, value: string) =>
    this.mutateState('draftData', (data) => ({
      ...data,
      [id]: {
        isNewColumn: value === 'new',
      },
    }));

  public getCustomVariableName = (id: string) =>
    this.currentState('customVariables').find((item) => item.id === id)?.name;

  public handleChangeCustomVariableId = (id: number, value: string, name: string) => {
    this.updateDraftId(id, value);
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].customVariableId = value;
      tempColumns[i].editing = value;
      tempColumns[i].customName = value === 'new' ? '' : name;
      tempColumns[i].questionId = null;
      return tempColumns;
    });
  };

  public handleChangeQuestionId = (id: number, value: string, name: string) => {
    this.updateDraftId(id, value);
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].questionId = value;
      tempColumns[i].editing = value;
      tempColumns[i].customName = value === 'new' ? '' : name;
      tempColumns[i].customVariableId = null;
      return tempColumns;
    });
  };

  public getIngestion = (id: number) => $get<ImportModalCsvTypes.Ingestion>(`ingestions/${id}`);

  public updateDraftData = () => {
    const data = this.currentState('draftData') || {};
    return !!Object.keys(data).length
      ? $patch<ImportModalCsvTypes.Ingestion>(`ingestions/${this.currentIngestion?.id}/draft`, {
          draft: data,
        }).pipe(catchError(() => of(null)))
      : of(null);
  };

  public commitChanges = (next = true, onSuccess?: () => void) => {
    const columns = this.currentState('columns');
    this.setState('loading', true);
    let tempColumns: ImportModalCsvTypes.ColumnToMap[] = [];
    let formattedColumns = columns.slice().map((col) => {
      const { id, customName, ignoreColumn, responseDataTypeId, extraInfo, customVariableId, questionId } =
        col;
      const tempCol = {
        id,
        customName,
        ignoreColumn,
        responseDataTypeId,
        extraInfo: {
          ...extraInfo,
          ...(responseDataTypeId === TC_IMPORT_DATE_RESPONSE_TYPE_ID && {
            timezone: extraInfo.timezone || this.currentDate.timezone,
          }),
        },
        ...(!this.isEmptyProject &&
          responseDataTypeId === TC_IMPORT_CUSTOM_VARIABLE_RESPONSE_TYPE_ID && {
            customVariableId: customVariableId === 'new' ? null : customVariableId,
          }),
        ...(!this.isEmptyProject &&
          [TC_IMPORT_TEXT_RESPONSE_TYPE_ID, TC_IMPORT_NPS_RESPONSE_TYPE_ID].includes(responseDataTypeId) && {
            questionId: questionId === 'new' ? null : questionId,
          }),
      };

      tempColumns = [...tempColumns, tempCol];

      return {
        ...col,
        ...tempCol,
      };
    });

    this.updateDraftData()
      .pipe(
        switchMap(() =>
          $put<ImportModalCsvTypes.Ingestion>(`ingestions/${this.currentIngestion?.id}/map-columns`, {
            mapping: tempColumns,
            goToNextStep: next,
          })
        )
      )
      .subscribe({
        next: (ingestion) => {
          this.setIngestionColumns(formattedColumns);
          this.setIngestion(ingestion);
          this.changeStep('columns_mapped');
          this.setSelectedColumnIds([]);
          onSuccess?.();
        },
        error: (err) => {
          this.setState('loading', false);
          const msg = (err?.message as string) || '';
          const isLongCustomVarNameError = msg.includes('Custom var name') && msg.includes('is too long');
          toast({
            severity: 'error',
            summary: isLongCustomVarNameError
              ? 'Custom variable name is too long'
              : msg || 'An error has ocurred. Try again later.',
            detail: isLongCustomVarNameError
              ? msg.replaceAll(/custom var name | is too long/gim, '')
              : undefined,
          });
        },
      });
  };

  public back = () => this.changeStep(null);

  public selectIcon = (responseDataTypeId: number) => {
    switch (responseDataTypeId) {
      case 1:
        return <Icon icon="star01Solid" size={16} color="amber500" className="block" />;
      case 2:
        return <Icon icon="typeAquareSolid" size={16} color="purple400" className="block" />;
      case 3:
        return <Icon icon="calendarSolid" size={16} color="cyan500" className="block" />;
      case 4:
        return <S.ScaleIcon />;
      default:
        return null;
    }
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useMapStepBLoC = () => useBLoC<BLoC>(Context);

export const MapStepBLoC: React.FC<BLoCParams<BLoC, State>> = ({ children }) => {
  const {
    $currentIngestion,
    $currentIngestionColumns,
    setIngestion,
    setIngestionColumns,
    setSelectedColumnIds,
    changeStep,
    isEmptyProject,
    project,
  } = useImportModalCsvBLoC();

  const ingestion = useObservableState($currentIngestion.pipe(first()));
  const columns = useObservableState($currentIngestionColumns.pipe(first()), []);
  const user = useObservableState(getUserStore().$user);

  const bloc = useInitBloc(
    () =>
      new BLoC(
        ingestion,
        columns,
        setIngestion,
        setIngestionColumns,
        setSelectedColumnIds,
        changeStep,
        isEmptyProject,
        user?.id,
        project
      ),
    [ingestion, columns]
  );

  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
