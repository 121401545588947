import React from 'react';
import { YabbleLogoStyle as S } from './YabbleLogo.style';
import { colourPalette } from 'styles/styles';

export type IconProps = {
  height?: number | 'full';
  width?: number | 'full';
  color?: keyof typeof colourPalette;
  colorAlpha?: number;
  testId?: string;
  className?: string;
};

export const YabbleLogo: React.FC<IconProps> = ({ className, testId, ...props }) => {
  return <S.Logo $props={props} data-testid={testId} className={className} />;
};
