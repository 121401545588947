import React, { useState } from 'react';
import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete';
import { PAutoCompleteEllipsisTemplate } from './components/PAutoCompleteEllipsisTemplate/PAutoCompleteEllipsisTemplate';
import { PAutoCompleteStyle as S } from './PAutoComplete.style';
import { useClassNames } from 'hooks/useClassNames';
import { Icon } from 'components/Icon/Icon';

export const PAutoComplete = React.forwardRef<
  AutoComplete,
  AutoCompleteProps & { invalid?: boolean; maxWidth?: number }
>((props, ref) => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const classNames = useClassNames(
    {
      'p-invalid': props.invalid,
      'overlay-panel-visible': overlayVisible,
      'is-disabled': props.disabled,
    },
    props.className
  );

  return (
    <S.Container>
      <S.AutoComplete
        itemTemplate={(i) => <PAutoCompleteEllipsisTemplate text={props.field ? i[props.field] : i} />}
        {...props}
        onShow={() => {
          props.onShow?.();
          setOverlayVisible(true);
        }}
        onHide={() => {
          props.onHide?.();
          setOverlayVisible(false);
        }}
        dropdownIcon={<Icon icon="chevronDownLine" />}
        className={classNames}
        ref={ref}
      />
      <Icon icon="alertCircleSolid" className="p-dropdown-invalid-icon" />
      <S.Global maxWidth={props?.maxWidth} />
    </S.Container>
  );
});
