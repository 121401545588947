import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { PButton } from 'components/prime/PButton/PButton';
import React from 'react';
import { useManageProjectPermissionsModalBLoC } from '../../ManageProjectPermissionsModal.bloc';

export const ManageProjectPermissionsFooter: React.FC = () => {
  const { closeModal } = useManageProjectPermissionsModalBLoC();
  return (
    <HStack justify="flex-end">
      <PButton label="Done" severity="secondary" onClick={() => closeModal()} />
    </HStack>
  );
};
