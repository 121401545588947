export namespace ManageProjectPermissionsModalTypes {
  export type ManageProjectPermissionsModalBLoCProps = {
    onClose: (complete?: boolean) => void;
    projectId: string;
  };

  export type User = {
    id: number;
    name: string;
  };

  export class FormValues {
    public user: User | null = null;
  }
}
