import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useMapStepBLoC } from '../../../../../../MapStep.bloc';
import { MapStepColumnMessagesStyle as S } from './MapStepColumnMessages.style';
import { Icon } from 'components/Icon/Icon';

export const MapStepColumnMessages: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({
  column,
}) => {
  const { $dateFieldCount, getAlertsByColumn } = useMapStepBLoC();
  const dateFieldCount = useObservableState($dateFieldCount);
  const alerts = getAlertsByColumn(column, dateFieldCount || 0);

  return (
    <S.Container>
      {alerts.map((alert, i) => (
        <S.Content key={i}>
          {alert.isMatched ? (
            <S.SuccessBox>
              <S.IconContainer>
                <Icon
                  icon="checkCircleSolid"
                  size={18}
                  color="green500"
                  className="block"
                  data-testid="icon-map-step-success"
                />
              </S.IconContainer>
              <S.SuccessText data-testid="label-map-step-matched-success">Matched</S.SuccessText>
            </S.SuccessBox>
          ) : (
            <S.Message
              severity={alert.severity}
              text={
                <S.MessageContent>
                  <S.MessageTitle data-testid="label-map-step-matched-error-title">
                    {alert.title}
                  </S.MessageTitle>
                  <S.MessageText data-testid="label-map-step-matched-error-message">
                    {alert.text}
                  </S.MessageText>
                </S.MessageContent>
              }
            />
          )}
        </S.Content>
      ))}
    </S.Container>
  );
};
