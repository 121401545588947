import React, { useState } from 'react';
import { ImportModalSyntheticCTAStyle as S } from './ImportModalSyntheticCTA.style';
import { PDivider } from 'components/prime/PDivider/PDivider';
import { useChatModalBLoC } from 'components/modals/ChatModal/ChatModal.bloc';
import { useImportModalSyntheticBLoC } from '../../ImportModalSynthetic.bloc';
import { useObservableState } from 'observable-hooks';
import { useImportModalBLoC } from 'components/modals/ImportModal/ImportModal.bloc';
import { ImportModalContent } from '../../../ImportModalContent/ImportModalContent';
import { ImportModalFooter } from '../../../ImportModalFooter/ImportModalFooter';
import { ImportModalHeader } from '../../../ImportModalHeader/ImportModalHeader';
import { useNavigate } from 'react-router-dom';
import { Box } from 'components/layouts/primitives/Box/Box';
import { Icon } from 'components/Icon/Icon';

export const ImportModalSyntheticCTA: React.FC = () => {
  const { setIds, openModal } = useChatModalBLoC();
  const { onClose } = useImportModalBLoC();
  const { $survey } = useImportModalSyntheticBLoC();
  const survey = useObservableState($survey);
  const navigate = useNavigate();
  const [chatModalOpened, setChatModalOpened] = useState(false);
  return (
    <S.Container>
      <ImportModalHeader title="Getting started" />
      <ImportModalContent>
        <S.Content>
          <S.Title>Hey there 👋</S.Title>
          <S.Paragraph>
            Great! Your survey data has successfully imported from the Yabble ChatGPT Plugin.
          </S.Paragraph>
          <PDivider className="m-0 p-0" />
          <S.Subtitle>Get started with your data</S.Subtitle>
          <S.Items>
            <S.Item>
              <Box fit="content">
                <Icon icon="checkCircleSolid" className="block" size={18} color="green600" />
              </Box>
              <S.ItemText>
                We’ve already sent it for counting. You’ll be notified when it’s ready for you to explore your
                themes and sub-themes.
              </S.ItemText>
            </S.Item>
            <S.Item>
              <Box fit="content">
                <Icon
                  icon={!chatModalOpened ? 'checkCircleLine' : 'checkCircleSolid'}
                  className="block"
                  size={18}
                  color={!chatModalOpened ? 'neutral900' : 'green600'}
                />
              </Box>
              <S.ItemText>
                Have a conversation with your data using Gen, our ultimate AI Research Assistant
              </S.ItemText>
            </S.Item>
            <S.Item>
              <Box fit="content">
                <Icon icon="checkCircleLine" className="block" size={18} color="neutral900" />
              </Box>
              <S.ItemText>Visualise and share your data using Stories</S.ItemText>
            </S.Item>
          </S.Items>
          <S.Subtitle>How can Gen help you?</S.Subtitle>
          <ul>
            <li>Ask Gen questions of your data and receive instant answers</li>
            <li>Chat across multiple projects and data sets</li>
          </ul>
        </S.Content>
      </ImportModalContent>
      <ImportModalFooter
        cancelFn={() => {
          onClose();
          navigate('/home');
        }}
        nextFn={() => {
          survey !== 'not-found' && setIds(survey?.projectId);
          setTimeout(() => {
            openModal();
            setChatModalOpened(true);
          });
        }}
        successLabel="Chat with Gen"
      />
    </S.Container>
  );
};
