import styled from 'styled-components';
import { Button } from 'primereact/button';
import { borderRadius, borders, colourPalette, hexToRGBA, padding } from 'styles/styles';

export const PButtonIconStyle = {
  Button: styled(Button)`
    min-width: auto;
    background: none;
    transition: 0.1s ease-out;
    justify-content: center;
    .button-icon-custom-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        width: 100%;
        height: 100%;
      }
    }
    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: ${borders.borderMD} solid ${colourPalette.neutral0};
      box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
    }
    &.p-disabled {
      opacity: 1;
    }

    &.button-icon-size-xs {
      width: 24px;
      height: 24px;
      padding: ${padding.paddingXXS};
      .button-icon-custom-wrapper {
        width: 16px;
        height: 16px;
      }
    }

    &.button-icon-size-sm {
      width: 32px;
      height: 32px;
      padding: ${padding.paddingXS};
      .button-icon-custom-wrapper {
        width: 20px;
        height: 20px;
      }
    }

    &.button-icon-size-md {
      width: 40px;
      height: 40px;
      padding: ${padding.paddingSM};
      .button-icon-custom-wrapper {
        width: 20px;
        height: 20px;
      }
    }

    &.button-icon-size-lg {
      width: 48px;
      height: 48px;
      padding: ${padding.paddingMD};
      .button-icon-custom-wrapper {
        width: 24px;
        height: 24px;
      }
    }

    &.button-icon-shape-squircle {
      &.button-icon-size-xs {
        border-radius: ${borderRadius.roundedXS};
      }
      &.button-icon-size-sm {
        border-radius: ${borderRadius.roundedSM};
      }
      &.button-icon-size-md {
        border-radius: ${borderRadius.roundedSM};
      }
      &.button-icon-size-lg {
        border-radius: ${borderRadius.roundedMD};
      }
    }

    &.button-icon-shape-round {
      border-radius: ${borderRadius.roundedFull} !important;
    }

    &.button-icon-severity-primary {
      background: transparent;
      .button-icon-custom-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral400};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral400};
          }
        }
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.neutral0};
      }
      &:hover {
        background: ${colourPalette.neutral100};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
      }
      &:active {
        background: transparent;
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral900};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral900};
            }
          }
        }
      }
      &.p-disabled {
        background: transparent !important;
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
      }
    }

    &.button-icon-severity-secondary {
      background: ${colourPalette.neutral100};
      .button-icon-custom-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral600};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral600};
          }
        }
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.neutral100};
      }
      &:hover {
        background: ${colourPalette.neutral200};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral900};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral900};
            }
          }
        }
      }
      &:active {
        background: ${colourPalette.neutral50};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
      }
      &.p-disabled {
        background: ${colourPalette.neutral100} !important;
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral400};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral400};
            }
          }
        }
      }
    }

    &.button-icon-severity-tertiary {
      background: ${colourPalette.neutral0};
      border: ${borders.borderSM} solid ${colourPalette.gray200};
      .button-icon-custom-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral600};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral600};
          }
        }
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.neutral0};
        border-color: ${colourPalette.neutral0};
      }
      &:hover {
        background: ${colourPalette.neutral100};
        border-color: ${colourPalette.gray300};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
      }
      &:active {
        background: ${colourPalette.neutral0};
        border-color: ${colourPalette.gray200};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral900};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral900};
            }
          }
        }
      }
      &.p-disabled {
        background: transparent !important;
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral300};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral300};
            }
          }
        }
      }
    }

    &.button-icon-severity-soft {
      background: ${colourPalette.blue50};
      .button-icon-custom-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.blue500};
          }
          &[stroke] {
            stroke: ${colourPalette.blue500};
          }
        }
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.blue50};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
      }
      &:hover {
        background: ${colourPalette.blue200};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.blue500};
            }
            &[stroke] {
              stroke: ${colourPalette.blue500};
            }
          }
        }
      }
      &:active {
        background: ${colourPalette.blue50};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.blue500};
            }
            &[stroke] {
              stroke: ${colourPalette.blue500};
            }
          }
        }
      }
      &.p-disabled {
        background: ${colourPalette.neutral100} !important;
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral400};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral400};
            }
          }
        }
      }
    }

    &.button-icon-severity-strong {
      background: ${colourPalette.blue500};
      .button-icon-custom-wrapper {
        svg path {
          &[fill] {
            fill: ${colourPalette.neutral0};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral0};
          }
        }
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        background: ${colourPalette.blue500};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral0};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral0};
            }
          }
        }
      }
      &:hover {
        background: ${colourPalette.blue600};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral0};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral0};
            }
          }
        }
      }
      &:active {
        background: ${colourPalette.blue400};
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral0};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral0};
            }
          }
        }
      }
      &.p-disabled {
        background: ${colourPalette.neutral100} !important;
        .button-icon-custom-wrapper {
          svg path {
            &[fill] {
              fill: ${colourPalette.neutral400};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral400};
            }
          }
        }
      }
    }
  `,
};
