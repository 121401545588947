import React from 'react';
import { ManageProjectPermissionsUserListStyle as S } from './ManageProjectPermissionsUserList.style';
import { HStack, VStack } from 'components/layouts/primitives/Stack/Stack';
import { useManageProjectPermissionsModalBLoC } from 'components/modals/ManageProjectPermissionsModal/ManageProjectPermissionsModal.bloc';
import { Notification } from 'components/Notification/Notification';
import { Text } from 'components/Text/Text';
import { useObservableState } from 'observable-hooks';
import { Box } from 'components/layouts/primitives/Box/Box';
import { PSkeleton } from 'components/prime/PSkeleton/PSkeleton';
import { ManageProjectPermissionsUser } from './components/ManageProjectPermissionsUser/ManageProjectPermissionsUser';

export const ManageProjectPermissionsUserList: React.FC = () => {
  const { $projectUsers } = useManageProjectPermissionsModalBLoC();
  const projectUsers = useObservableState($projectUsers);

  return (
    <VStack gap="gap2">
      <HStack justify="space-between">
        <Text color="neutral400" size="fontSizeSM" weight="medium" lineHeight="lineHeightMD">
          Users
        </Text>
        {projectUsers ? (
          <Text color="neutral400" size="fontSizeSM" lineHeight="lineHeightMD">
            {projectUsers?.length} users
          </Text>
        ) : (
          <PSkeleton height="24px" width="60px" />
        )}
      </HStack>
      {projectUsers ? (
        projectUsers.length ? (
          <S.Scroller>
            {projectUsers.map((user) => (
              <ManageProjectPermissionsUser key={user.id} user={user} />
            ))}
          </S.Scroller>
        ) : (
          <S.EmptyBox>
            <VStack stretch align="center" justify="center">
              <Text color="neutral400" size="fontSizeSM" weight="medium" lineHeight="lineHeightMD">
                No users added to project
              </Text>
            </VStack>
          </S.EmptyBox>
        )
      ) : (
        <S.Scroller>
          {Array.from({ length: 6 }).map((_u, i) => (
            <Box key={i} padding={['paddingSM', 'paddingNone']}>
              <HStack align="center" gap="gap3" splitAfterIdx={1}>
                <PSkeleton height="32px" width="32px" borderRadius="50%" />
                <PSkeleton height="24px" width="80px" />
              </HStack>
            </Box>
          ))}
        </S.Scroller>
      )}
      <Notification
        layoutType="compact"
        severity="informative"
        title="Users of this project can generate insights and manage data"
      />
    </VStack>
  );
};
