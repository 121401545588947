import React from 'react';
import { ManageProjectPermissionsModalBLoC as BLoC } from './ManageProjectPermissionsModal.bloc';
import { ManageProjectPermissionsModalTypes } from './ManageProjectPermissionsModal.types';
import { PDialogV2 } from 'components/prime/PDialogV2/PDialogV2';
import { ManageProjectPermissionsFooter } from './components/ManageProjectPermissionsFooter/ManageProjectPermissionsFooter';
import { ManageProjectPermissionsContent } from './components/ManageProjectPermissionsContent/ManageProjectPermissionsContent';

export const ManageProjectPermissionsModal: React.FC<
  ManageProjectPermissionsModalTypes.ManageProjectPermissionsModalBLoCProps
> = (props) => {
  return (
    <BLoC {...props}>
      <PDialogV2
        onHide={props.onClose}
        visible={true}
        title="Manage permissions"
        footer={<ManageProjectPermissionsFooter />}
      >
        <ManageProjectPermissionsContent />
      </PDialogV2>
    </BLoC>
  );
};
