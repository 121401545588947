import { Dropdown } from 'primereact/dropdown';
import styled, { css } from 'styled-components';
import {
  borderRadius,
  borders,
  colourPalette,
  fontSize,
  fontWeight,
  gap,
  hexToRGBA,
  lineHeight,
  padding,
  shadow,
} from 'styles/styles';

export const PDropdownBaseStyle = css`
  &.p-dropdown {
    border-radius: ${borderRadius.roundedSM};
    background-color: ${colourPalette.neutral0};
    border: ${borders.borderSM} solid ${colourPalette.gray200};
    display: flex;
    align-items: center;
    min-height: 40px;

    .p-dropdown-label {
      padding: 0 ${gap.gap5} 0 ${gap.gap3};
      max-height: fit-content;
      font-size: ${fontSize.fontSizeSM};
      line-height: ${lineHeight.lineHeightMD};
      font-family: 'SuisseIntl', sans-serif;
      color: ${colourPalette.neutral600};
      &.p-placeholder {
        color: ${colourPalette.neutral400};
      }
      &::placeholder {
        color: ${colourPalette.neutral400};
      }
    }

    .p-dropdown-clear-icon {
      width: 16px;
      height: 16px;
      path {
        &[fill] {
          fill: ${colourPalette.neutral600};
        }
        &[stroke] {
          stroke: ${colourPalette.neutral600};
        }
      }
    }

    .p-dropdown-trigger {
      width: fit-content;
      padding: 0 ${gap.gap3} 0 ${gap.gap2};
      & > svg {
        width: 16px;
        height: 16px;
        path {
          &[fill] {
            fill: ${colourPalette.neutral600};
          }
          &[stroke] {
            stroke: ${colourPalette.neutral600};
          }
        }
      }
      &[aria-expanded='true'] {
        & > svg {
          transform: rotate(180deg);
        }
      }
    }

    &:hover {
      border-color: ${colourPalette.gray300};
      background-color: ${colourPalette.neutral100};
    }
    &:focus,
    &.p-inputwrapper-focus,
    &:focus-within,
    &.p-invalid:focus-within {
      border-color: ${colourPalette.blue500};
      box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
      background-color: ${colourPalette.neutral0};
    }

    &.p-invalid {
      border-color: ${colourPalette.rose500};
      .p-dropdown-label {
        color: ${colourPalette.rose600};
        &.p-placeholder,
        &::placeholder {
          color: ${colourPalette.rose600};
        }
      }
      & + .p-dropdown-invalid-icon {
        display: block;
      }
    }

    &.p-disabled {
      opacity: 1;
      background-color: ${colourPalette.neutral50};
      border-color: ${colourPalette.gray200};
      .p-dropdown-label {
        color: ${colourPalette.neutral300};
        &.p-placeholder,
        &::placeholder {
          color: ${colourPalette.neutral300};
        }
      }
      .p-dropdown-trigger > svg > path {
        &[fill] {
          fill: ${colourPalette.neutral300};
        }
        &[stroke] {
          stroke: ${colourPalette.neutral300};
        }
      }
      & + .p-dropdown-invalid-icon {
        display: none;
      }
    }

    &.p-dropdown-sm,
    .p-dropdown-sm {
      min-height: 32px;
    }
    &.p-dropdown-lg,
    .p-dropdown-lg {
      min-height: 56px;
      font-size: 18px;
    }

    &.p-dropdown-type-inline {
      background-color: transparent;
      border-color: transparent;
      &:hover {
        border-color: transparent;
        background-color: ${colourPalette.neutral100};
      }
      &:focus,
      &.p-inputwrapper-focus,
      &:focus-within,
      &.p-invalid:focus-within {
        border-color: transparent;
        background-color: transparent;
      }
      &.p-invalid {
        border-color: ${colourPalette.rose500};
        .p-dropdown-label {
          color: ${colourPalette.rose600};
          &.p-placeholder,
          &::placeholder {
            color: ${colourPalette.rose600};
          }
        }
        & + .p-dropdown-invalid-icon {
          display: block;
        }
      }
      &.p-disabled {
        background-color: transparent;
        border-color: transparent;
      }
      &:not(.p-invalid):not(.p-dropdown-clearable) {
        .p-dropdown-label {
          padding-right: ${padding.paddingNone};
        }
      }
    }
  }
`;

export const PDropdownStyle = {
  Container: styled.div`
    position: relative;
    min-width: 0;
    & > .p-dropdown-invalid-icon {
      display: none;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 38px;
      pointer-events: none;
      path {
        &[fill] {
          fill: ${colourPalette.rose500};
        }
        &[stroke] {
          stroke: ${colourPalette.rose500};
        }
      }
    }
    &.fit-content {
      width: fit-content;
    }
  `,
  Dropdown: styled(Dropdown)`
    ${PDropdownBaseStyle}
  `,
};

export const PDropdownGlobalStyle = css`
  div.p-dropdown-panel.p-component {
    box-shadow: ${shadow.shadowMD};
    max-width: 500px;

    & .p-dropdown-header {
      padding: ${gap.gap2} !important;
      border-bottom: 1px solid ${colourPalette.neutral300} !important;
      .p-dropdown-filter {
        border-radius: ${borderRadius.roundedSM};
        padding: 0 ${gap.gap3} 0 32px;
        height: 40px;
        background-color: ${colourPalette.neutral0} !important;
        border: ${borders.borderSM} solid ${colourPalette.gray200};
        &::placeholder {
          color: ${colourPalette.neutral400};
        }
        &:hover {
          border-color: ${colourPalette.gray300} !important;
          background-color: ${colourPalette.neutral100} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)} !important;
          border-color: ${colourPalette.blue500} !important;
          background-color: ${colourPalette.neutral0} !important;
        }
      }
      .p-dropdown-filter-icon {
        right: auto;
        left: 12px;
        font-size: 15px;
        transform: translateY(-50%);
        margin: 0;
      }
    }

    .p-dropdown-items-wrapper {
      overflow: auto;
      ::-webkit-scrollbar-track {
        border-left: ${borders.borderSM} solid ${colourPalette.neutral300};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${colourPalette.neutral300};
      }
    }

    .p-dropdown-items {
      padding: ${padding.paddingXS};
      & > .p-dropdown-item {
        height: 40px;
        padding: ${padding.paddingInput} ${padding.paddingMD};
        border-radius: ${borderRadius.roundedSM};
        color: ${colourPalette.neutral600};
        font-weight: ${fontWeight.regular};
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        &.p-highlight {
          background-color: ${colourPalette.blue50};
          color: ${colourPalette.blue500};
        }
        &:hover {
          background-color: ${colourPalette.neutral100};
          color: ${colourPalette.neutral600};
        }
      }

      & > .p-dropdown-empty-message {
        height: 36px;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
      }

      & > .p-dropdown-item-group {
        padding: ${padding.paddingSM} ${padding.paddingMD} 0;
        height: 36px;
        display: flex;
        align-items: center;
        margin: 0 0 ${gap.gap2} 0;
        color: ${colourPalette.gray600};
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        text-transform: uppercase;
        position: relative;
        &:not(:first-child) {
          border-top: ${borders.borderSM} solid ${colourPalette.gray300};
          margin-top: ${gap.gap2} !important;
        }
      }
    }

    .p-dropdown-header {
      padding: ${padding.paddingXXS} ${padding.paddingXXS} 0;
      border-bottom: none;

      & .p-inputtext {
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 36px;
        font-size: ${fontSize.fontSizeSM};
        line-height: ${lineHeight.lineHeightMD};
        background-color: ${colourPalette.neutral0};
        border: ${borders.borderSM} solid ${colourPalette.gray200};
        &:hover {
          border-color: ${colourPalette.gray300};
        }
        &.p-invalid {
          border-color: ${colourPalette.rose500};
        }
        &:focus,
        &.p-invalid:focus {
          box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
          border-color: ${colourPalette.blue500};
        }
      }
    }
  }

  .p-multiselect-items {
    .p-multiselect-item {
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      & > span {
        min-width: 0;
        text-overflow: ellipsis;
        flex: 1;
        overflow: hidden;
      }
    }
  }

  .header-items-no-padding.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    padding: 0px;
  }
`;
