import React from 'react';
import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCBase, BLoCParams, renderBlocChild } from 'types/BLoCBase';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { PersonaLibraryModalTypes } from './PersonaLibraryModal.types';
import { $get, $getWithMeta } from 'services/api';
import { getCustomerStore } from 'stores/customer.store';

type State = {
  page: number;
  search?: string;
  selectedGroup?: PersonaLibraryModalTypes.PersonaGroup | null;
  selectedSection: 'projects' | 'personas';
};

class BLoC extends BLoCBase<State> {
  public $selectedGroup = this.$getState('selectedGroup');
  public $selectedSection = this.$getState('selectedSection');
  public $page = this.$getState('page');
  private $groupsWithMeta = combineLatest([
    getCustomerStore().$customerSlug,
    this.$getState('search'),
    this.$page,
  ]).pipe(
    debounceTime(0),
    distinctUntilChanged(),
    switchMap(([slug, search, page]) =>
      $getWithMeta(`persona-library/customers/${slug}/ad-groups`, {
        page,
        perPage: 10,
        ...(!!search && { 'filter[name]': search }),
      }).pipe(
        startWith([null, null]),
        tap(() => this.setState('selectedGroup', null))
      )
    ),
    shareReplay()
  );

  public $groups = this.$groupsWithMeta.pipe(map((pwm) => (pwm?.length ? pwm[0] : null))) as Observable<
    PersonaLibraryModalTypes.PersonaGroup[] | null
  >;

  public $meta = this.$groupsWithMeta.pipe(map((gwm) => gwm?.[1] || null)) as Observable<{
    total: number;
    currentPage: number;
    from: number;
    perPage: number;
  }>;

  public $projects = this.$selectedGroup.pipe(
    switchMap((selectedGroup) =>
      selectedGroup
        ? $get<PersonaLibraryModalTypes.Project[]>(
            `persona-library/ad-groups/${selectedGroup.id}/ad-project-templates`
          ).pipe(
            startWith(null),
            catchError(() => of([]))
          )
        : of([])
    ),
    shareReplay()
  );

  constructor(public onClose: PersonaLibraryModalTypes.PersonaLibraryModalProps['onClose']) {
    super({ page: 1, selectedSection: 'personas' });
  }

  public setSelectedGroup = (id: State['selectedGroup']) => this.setState('selectedGroup', id);
  public paginate = (page: State['page']) => this.setState('page', page);
  public search = (search: State['search']) => this.setStates({ page: 1, search });
  public setSelectedSection = (section: 'projects' | 'personas') => this.setState('selectedSection', section);

  public setPersonaGroup = () => {
    this.onClose(this.currentState('selectedGroup'));
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const usePersonaLibraryModalBLoC = () => useBLoC<BLoC>(Context);

export const PersonaLibraryModalBLoC: React.FC<
  BLoCParams<BLoC, State> & PersonaLibraryModalTypes.PersonaLibraryModalProps
> = ({ children, onClose }) => {
  const bloc = useInitBloc(() => new BLoC(onClose));
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
