import { Box } from 'components/layouts/primitives/Box/Box';
import { VStack } from 'components/layouts/primitives/Stack/Stack';
import { LinkText } from 'components/LinkText/LinkText';
import { Text } from 'components/Text/Text';
import React from 'react';
import { ManageProjectPermissionsUserList } from './components/ManageProjectPermissionsUserList/ManageProjectPermissionsUserList';
import { ManageProjectPermissionsUserSearch } from './components/ManageProjectPermissionsUserSearch/ManageProjectPermissionsUserSearch';
import { useObservableState } from 'observable-hooks';
import { getUserStore } from 'stores/user.store';

export const ManageProjectPermissionsContent: React.FC = () => {
  const userRole = useObservableState(getUserStore().$userRole);

  return (
    <VStack>
      <Text size="fontSizeSM" lineHeight="lineHeightMD" color="neutral500">
        Manage who has access to this project. All admins have access to this project. To see your team, go to{' '}
        <LinkText as="Link" to="/settings/users">
          Users
        </LinkText>{' '}
        in Settings.
      </Text>
      <Box padding={['paddingXL', 'paddingNone', 'paddingNone', 'paddingNone']}>
        <VStack gap="gap5">
          {userRole === 'administrator' && <ManageProjectPermissionsUserSearch />}
          <ManageProjectPermissionsUserList />
        </VStack>
      </Box>
    </VStack>
  );
};
