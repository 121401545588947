import styled, { createGlobalStyle } from 'styled-components';
import { AutoComplete } from 'primereact/autocomplete';
import {
  borderRadius,
  borders,
  colourPalette,
  fontWeight,
  fontSize,
  hexToRGBA,
  padding,
  shadow,
  lineHeight,
} from 'styles/styles';

export const PAutoCompleteStyle = {
  Container: styled.div`
    position: relative;
    & > .p-dropdown-invalid-icon {
      display: none;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 38px;
      pointer-events: none;
      path {
        &[fill] {
          fill: ${colourPalette.rose500};
        }
        &[stroke] {
          stroke: ${colourPalette.rose500};
        }
      }
    }
  `,
  AutoComplete: styled(AutoComplete)<{ invalid?: boolean }>`
    &.p-autocomplete {
      width: 100%;
      height: 40px;
      position: relative;

      & > .p-inputtext {
        width: 100%;
        border-radius: ${borderRadius.roundedSM};
        background-color: ${colourPalette.neutral0};
        border: ${borders.borderSM} solid ${colourPalette.gray200};
        padding: 0 46px 0 12px;
        height: 40px;
        font-size: ${fontSize.fontSizeSM};
        line-height: ${lineHeight.lineHeightMD};

        &:hover {
          border-color: ${colourPalette.gray300};
          background-color: ${colourPalette.neutral100};
        }

        &:focus,
        &:focus-within,
        &:active {
          border-color: ${colourPalette.blue500};
          box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
          background-color: ${colourPalette.neutral0};
        }

        &::placeholder {
          color: ${colourPalette.neutral400};
        }

        &:disabled {
          background-color: ${colourPalette.neutral50};
          border-color: ${colourPalette.gray200};
          opacity: 1;
          color: ${colourPalette.neutral300};
          &::placeholder {
            color: ${colourPalette.neutral300};
          }
        }
      }

      & > .p-autocomplete-dropdown {
        background: none;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        justify-content: flex-end;
        svg {
          width: 16px;
          height: 16px;
          path {
            &[fill] {
              fill: ${colourPalette.neutral600};
            }
            &[stroke] {
              stroke: ${colourPalette.neutral600};
            }
          }
        }
        &:disabled {
          background: transparent !important;
          svg {
            path {
              &[fill] {
                fill: ${colourPalette.neutral300};
              }
              &[stroke] {
                stroke: ${colourPalette.neutral300};
              }
            }
          }
        }
      }

      &.overlay-panel-visible > .p-autocomplete-dropdown > svg {
        transform: rotate(180deg);
      }

      &.p-invalid {
        & > .p-inputtext {
          border-color: ${colourPalette.rose500};
          &::placeholder {
            color: ${colourPalette.rose600};
          }
          &:focus,
          &:focus-within,
          &:active {
            border-color: ${colourPalette.blue500};
            box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
            background-color: ${colourPalette.neutral0};
          }
        }
        & + .p-dropdown-invalid-icon {
          display: block;
        }
      }

      &.is-disabled {
        & + .p-dropdown-invalid-icon {
          display: none;
        }
      }
    }
  `,
  Global: createGlobalStyle<{ maxWidth?: number }>`
    div.p-autocomplete-panel.p-component {
      width: fit-content;
      box-shadow: ${shadow.shadowMD};
      ${({ maxWidth }) => (maxWidth ? ` max-width: ${maxWidth}px` : 'max-width: 95vw')};

      & > .p-autocomplete-items-wrapper {
        ::-webkit-scrollbar-track {
          border-left: ${borders.borderSM} solid ${colourPalette.neutral300};
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${colourPalette.neutral300};
        }

        .p-autocomplete-items {
         padding: ${padding.paddingXS};

        & > .p-autocomplete-item{
          height: 40px;
          padding: ${padding.paddingInput} ${padding.paddingMD};
          border-radius: ${borderRadius.roundedSM};
          color: ${colourPalette.neutral600};
          font-weight: ${fontWeight.regular}; 

          &:focus,
          &:focus-visible,
          &:active {
            background-color: ${colourPalette.neutral0};
            box-shadow: 0px 0px 0px 2px ${hexToRGBA(colourPalette.blue600, 0.3)};
            position: relative;
            z-index: 1;
          }
          
          &.p-highlight {
            background-color: ${colourPalette.blue50};
            span {
              color: ${colourPalette.blue500};
            }
          }

          &:hover{
            background-color: ${colourPalette.neutral100};
          } 
        }
      } 
      }
    }
  `,
};
