import React, { useMemo } from 'react';
import { AvatarStyle as S } from './Avatar.style';
import { colourPalette } from 'styles/styles';
import { Text } from 'components/Text/Text';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { useClassNames } from 'hooks/useClassNames';
import { User as UserGlobalType } from 'types/User';

type SimpleUser = { id: number; name?: string };
type User = Pick<UserGlobalType, 'id' | 'firstName' | 'lastName'>;
type AvatarProps = {
  user?: User | SimpleUser | string;
  url?: string;
  bgColor?: keyof typeof colourPalette;
  bgColorAlpha?: number;
  color?: keyof typeof colourPalette;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
};

const getNameInitials = (name: string) => {
  const splitName = name.trim().split(' ');
  if (splitName.length === 1) return name.slice(0, 2);

  return splitName.length >= 3
    ? `${splitName[0][0] + splitName[2][0]}`
    : `${splitName[0][0] + splitName[1][0]}`;
};

export const Avatar: React.FC<AvatarProps> = ({
  user,
  color,
  bgColor,
  bgColorAlpha,
  size = 'md',
  url,
  className,
}) => {
  const initials = useMemo(() => {
    let text = '';
    if (user) {
      if (typeof user === 'string') text = getNameInitials(user);
      if ((user as SimpleUser).name) text = getNameInitials((user as SimpleUser).name || '');
      if ((user as User).firstName || (user as User).lastName)
        text = getNameInitials(`${(user as User).firstName} ${(user as User).lastName}`);
    }
    return text.toLocaleUpperCase();
  }, [user]);

  const avatarContainerClasses = useClassNames(
    { xs: size === 'xs', sm: size === 'sm', md: size === 'md', lg: size === 'lg', xl: size === 'xl' },
    className
  );

  return (
    <S.Container
      radius="roundedFull"
      backgroundColor={bgColor || 'neutral200'}
      backgroundColorAlpha={bgColorAlpha}
      className={avatarContainerClasses}
    >
      <HStack stretch align="center" justify="center">
        {url ? (
          <img className="avatar-image" src={url} alt={initials} />
        ) : (
          <Text className="avatar-text" color={color || 'neutral900'} weight="medium">
            {initials}
          </Text>
        )}
      </HStack>
    </S.Container>
  );
};
