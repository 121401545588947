import styled from 'styled-components';
import { Message } from 'primereact/message';
import { colourPalette, colours, hexToRGBA, padding } from 'styles/styles';

export const PMessageStyle = {
  Message: styled(Message)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: ${padding.paddingMD};
    &.p-inline-message.p-inline-message-info {
      background-color: #e1eafc;
      color: ${colours.neutralCharcoal};
    }
    &.p-inline-message.p-inline-message-warn {
      background-color: #fff4db;
      color: ${colours.neutralCharcoal};
    }
    &.p-inline-message.p-inline-message-error {
      background-color: ${hexToRGBA(colourPalette.rose500, 0.05)};
      color: ${colourPalette.rose600};
    }
    &.p-inline-message.p-inline-message-success {
      background-color: #e3f2e6;
      color: ${colours.neutralCharcoal};
    }
  `,
  IconWrapper: styled.div`
    position: relative;
    top: 2px;
    & > svg {
      margin-right: 12px;
    }
  `,
};
