import { Avatar } from 'components/Avatar/Avatar';
import { Box } from 'components/layouts/primitives/Box/Box';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { useManageProjectPermissionsModalBLoC } from 'components/modals/ManageProjectPermissionsModal/ManageProjectPermissionsModal.bloc';
import { ManageProjectPermissionsModalTypes } from 'components/modals/ManageProjectPermissionsModal/ManageProjectPermissionsModal.types';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';
import { PTooltip } from 'components/prime/PTooltip/PTooltip';
import { Text } from 'components/Text/Text';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { confirmDialogV2 } from 'services/confirmDialogV2';
import { getUserStore } from 'stores/user.store';

export const ManageProjectPermissionsUser: React.FC<{ user: ManageProjectPermissionsModalTypes.User }> = ({
  user,
}) => {
  const userRole = useObservableState(getUserStore().$userRole);
  const { $loading, removeUserPermisionInProject } = useManageProjectPermissionsModalBLoC();
  const loading = useObservableState($loading);

  return (
    <Box key={user.id} padding={['paddingSM', 'paddingNone']}>
      <HStack gap="gap3" align="center" splitAfterIdx={1}>
        <Box>
          <Avatar size="lg" user={user} color="purple600" bgColor="purple500" bgColorAlpha={0.08} />
        </Box>
        <Text
          ellipsis
          color="neutral600"
          size="fontSizeSM"
          weight="medium"
          lineHeight="lineHeightMD"
          transform="upper-first-letter"
        >
          {user.name}
        </Text>
        {userRole === 'administrator' && (
          <Box className={`manage-project-permissions-user-${user.id}`}>
            <PButtonIcon
              size="sm"
              icon="userX01Line"
              disabled={loading === 'removingUser'}
              onClick={() =>
                confirmDialogV2({
                  title: 'Remove user',
                  acceptLabel: 'Yes, Remove',
                  description:
                    'Removing user will revoke their access to this project. You can add them back later at any time.',
                  onAccept: () => removeUserPermisionInProject(user),
                })
              }
            />
            <PTooltip
              target={`.manage-project-permissions-user-${user.id}`}
              position="top"
              my="center bottom-8"
              content="Remove user"
            />
          </Box>
        )}
      </HStack>
    </Box>
  );
};
