import styled from 'styled-components';
import { Box } from 'components/layouts/primitives/Box/Box';
import { borderRadius, colourPalette, lineHeight, padding } from 'styles/styles';

export const NotificationStyle = {
  Container: styled(Box)`
    &.notification-severity-generic {
      box-shadow: -2px 0px 0px 0px ${colourPalette.violet500};
    }
    &.notification-severity-informative {
      box-shadow: -2px 0px 0px 0px ${colourPalette.sky500};
    }
    &.notification-severity-success {
      box-shadow: -2px 0px 0px 0px ${colourPalette.green500};
    }
    &.notification-severity-warning {
      box-shadow: -2px 0px 0px 0px ${colourPalette.amber500};
    }
    &.notification-severity-error {
      box-shadow: -2px 0px 0px 0px ${colourPalette.rose500};
    }
    &.notification-layout-type-compact {
      border-top-right-radius: ${borderRadius.roundedMD};
      border-bottom-right-radius: ${borderRadius.roundedMD};
    }
    &.notification-layout-type-spacious {
      border-radius: 0 ${borderRadius.roundedMD} ${borderRadius.roundedMD} 0;
    }
    &.notification-layout-type-compact {
      border-radius: ${borderRadius.roundedMD};
      box-shadow: none;
    }
    .notification-icon {
      height: ${lineHeight.lineHeightMD};
      padding-top: ${padding.paddingXXXS};
    }
  `,
};
